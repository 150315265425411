<template>
	<PageLayout :title="$t('deposit.title')">
		<div v-if="!loading" class="cashier-box cashier-box--deposit">
			<form id="gatewayRedir" action="#" method="post">
				<input type="hidden" name="version" value="1.0" />
				<input id="encodedMessage" type="hidden" name="encodedMessage" />
				<input id="signature" type="hidden" name="signature" />
			</form>
			<div>
				<payment-logo :custom-logo="'monopoly.svg'" />
				<div class="small-width overflow-visible"></div>

				<div class="line-separator-wrapper no-mr-top">
					<div class="line-separator" />
				</div>

				<amount-buttons
					:buttons-value="currentMethod.predefinedDepositAmounts"
					:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
					:currency="'USD'"
					:form-errors="formErrors['amount']"
					@set-button-value="setButtonValue"
				/>

				<button type="button" class="button primary-button deposit center" @click="pay()">
					{{ $t('deposit.top-up') }}
				</button>

				<div v-if="loading" class="loader-wrapper">
					<loader />
				</div>
			</div>
		</div>
	</PageLayout>
</template>
<script>
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'MonopolyMoney',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		amountButtons,
		paymentWallet,
	},
	data() {
		return {
			form: {
				amount: null,
			},
			formErrors: {
				amount: null,
			},
			loading: false,
			paymentDone: false,
			walletId: null,
		};
	},
	computed: {
		currentMethod() {
			return this.$store.getters.getCurrentMethod('monopolymoney');
		},
	},
	async mounted() {
		await this.getUserWallet();
	},

	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		async createTransaction(amount, currency, walletId) {
			const response = await apiClient.monopolyCreateTransaction(amount, currency, walletId);
			if (response.status === 201) {
				return response.data.transactionId;
			}
			throw new Error('Invalid response from create transaction');
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		async redirectToConfirmation(amount) {
			const transactionId = await this.createTransaction(amount, 'USD', this.walletId);
			this.$router.push({
				path: `/payments/deposit/${this.$route.params.walletId}/monopolymoney_confirmation`,
				query: { amount, transactionId },
			});
		},
		async pay() {
			this.loading = true;
			try {
				await this.redirectToConfirmation(this.form.amount);
			} catch (e) {
				const walletId = this.$route.params.walletId;
				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { status: 'error' },
				});
				console.error('Failed to create monopoly transaction', e);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
<style scoped>
#gatewayRedir {
	visibility: hidden;
}

.no-mr-top {
	margin-top: 0px;
}
</style>
